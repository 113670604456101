@layer components {
  @keyframes slideshow--first-dot {

    0%,
    20%,
    100% {
      @apply bg-primary-500;
    }

    40%,
    60% {
      @apply bg-primary-200;
    }

    80%,
    90% {
      @apply bg-primary-200;
    }
  }

  @keyframes slideshow--second-dot {

    0%,
    20%,
    100% {
      @apply bg-primary-200;
    }

    40%,
    60% {
      @apply bg-primary-500;
    }

    80%,
    90% {
      @apply bg-primary-200;
    }
  }

  @keyframes slideshow--third-dot {

    0%,
    20%,
    100% {
      @apply bg-primary-200;
    }

    40%,
    60% {
      @apply bg-primary-200;
    }

    80%,
    90% {
      @apply bg-primary-500;
    }
  }

  @keyframes slideshow--desktop--slide {

    0%,
    20%,
    100% {
      transform: translateX(0px);
    }

    40%,
    60% {
      transform: translateX(-410px);
    }

    80%,
    90% {
      transform: translateX(-820px);
    }
  }

  @keyframes slideshow--mobile--slide {

    0%,
    20%,
    100% {
      transform: translateX(0px);
    }

    40%,
    60% {
      transform: translateX(-322px);
    }

    80%,
    90% {
      transform: translateX(-644px);
    }
  }

  @keyframes carousel--autoplay {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-135px * 11));
    }
  }

  /*
    When apply these filter styles with tailwind, because the internal sort of
    utilities, can occur an unexpected color of icon, like resulting green
    instead of blue.
  */
  .facebook-secondary-icon-color {
    filter: invert(65%) sepia(31%) saturate(6227%) hue-rotate(204deg) brightness(99%) contrast(92%) !important;
  }

  /*
    This style are part of the `GoogleLogin` component. This aren't applied inside
    of component because was not found a better way to apply and force this style
    to `.g_id_signin > div > div:first-of-type` present inside of iframe.
  */
  .g_id_signin>div>div:first-of-type {
    display: none;
  }

  .g_id_signin>div>div:last-of-type {
    display: none;
  }

  .g_id_signin iframe {
    display: none !important;
  }

  .GoogleLoginButtonContainer {
    height: 44px;
    width: auto;
  }

  /* EmojiPicker stuff */
  emoji-picker {
    --border-radius: 0.5rem;
    --border-size: 0px;
    --category-emoji-padding: 0.5rem;
    --category-emoji-size: 1.5rem;
    --emoji-size: 1.4rem;
    --indicator-height: 3px;
    --input-border-radius: 0.25rem;
    --input-border-size: 1px;
    --input-font-size: 1rem;
    --input-line-height: 1.5;
    --num-columns: 6;
    --outline-size: 2px;
    --skintone-border-radius: 8px;

    @apply absolute right-1/2 top-[calc(100%_+_1.5rem)] z-2 block translate-x-[60%] md:translate-x-1/2;
  }

  @media (min-width: 1024px) {
    emoji-picker {
      --num-columns: 8;
    }
  }

  html.light emoji-picker {
    --background: rgb(var(--neutral-high-100));
    --border-color: rgb(var(--neutral-high-300));
    --button-active-background: rgb(var(--primary-200));
    --button-hover-background: rgb(var(--neutral-high-200));
    --indicator-color: rgb(var(--primary-300));
    --input-border-color: rgb(var(--neutral-high-500));
    --input-font-color: rgb(var(--neutral-low-100));
    --input-placeholder-color: rgb(var(--neutral-high-500));
    --outline-color: rgb(var(--primary-300));
  }

  html.dark emoji-picker {
    --background: rgb(var(--neutral-low-400));
    --border-color: rgb(var(--neutral-high-500));
    --button-active-background: rgb(var(--primary-400));
    --button-hover-background: rgb(var(--neutral-low-100));
    --indicator-color: rgb(var(--primary-300));
    --input-border-color: rgb(var(--neutral-high-500));
    --input-font-color: rgb(var(--neutral-high-200));
    --input-placeholder-color: rgb(var(--neutral-high-500));
    --outline-color: rgb(var(--primary-300));
  }

  .scrollbar-light {
    &::-webkit-scrollbar {
      @apply max-h-2 w-2;
    }

    &::-webkit-scrollbar-track {
      @apply bg-transparent;
    }

    &::-webkit-scrollbar-thumb {
      @apply rounded-2 bg-neutral-high-300/80 transition dark:bg-neutral-low-300/80;
    }
  }
}
